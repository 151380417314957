<template>
  <v-container fluid fill-height class="login-container">
    <v-col cols="12" lg="12" class="d-flex align-center">
      <v-container>
        <div
          class="d-flex align-center justify-center"
          style="min-height: calc(100vh - 148px)"
        >
          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-card elevation="0" class="loginBox">
                <v-card elevation="2">
                  <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
                    <span class="text-h6">Login</span>
                  </v-toolbar>

                  <v-card-text class="pb-6 px-6 pt-0">
                    <v-form class="mt-7">
                      <AError :api="api"></AError>

                      <transition name="fade">
                        <v-alert
                          v-if="message"
                          dense
                          text
                          type="success"
                          class="text-pre-wrap"
                          >{{ message }}</v-alert
                        >
                      </transition>

                      <div class="">
                        <v-label class="text-overline">Email Address</v-label>
                        <v-text-field
                          required
                          outlined
                          dense
                          v-model="form.email"
                          @keyup.native.enter="fetch"
                          class="mt-2"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-label class="text-overline">{{
                          $t("model.prop.password")
                        }}</v-label>
                        <v-text-field
                          required
                          outlined
                          dense
                          type="password"
                          v-model="form.password"
                          @keyup.native.enter="fetch"
                          class="mt-2"
                        ></v-text-field>
                      </div>

                      <div class="d-flex justify-content-between mb-3">
                        <div>
                          <router-link
                            :to="{ name: 'PageResetPassword' }"
                            class="text-overline link-hover"
                            >Reset Password?</router-link
                          >
                        </div>

                        <div class="text-right">
                          <router-link
                            :to="{ name: 'PageGuestLoginFirstTime' }"
                            class="text-overline link-hover"
                            >First Time Login?</router-link
                          >
                        </div>
                      </div>

                      <v-card-actions class="pa-0">
                        <v-btn
                          :loading="api.isLoading"
                          :color="HRDC_BLUE_THEME_COLOR"
                          block
                          depressed
                          x-large
                          class="text-decoration-none text-white"
                          @click="fetch()"
                          >Login</v-btn
                        >
                      </v-card-actions>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  props: ["message"],
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    api: new Api(),
    hrdcData: new HrdcData(),
  }),
  created() {
    this.api.setMethod(`POST`);
    this.api.setUrl(`${this.$service.sso}/v1/en/auth/login`);
    this.api.setCallbackCompleted((resp) => {
      this.$store.commit("updateAuth", resp);
      this.$router.push({ name: "PageConsoleDashboard" });
    });
  },
  methods: {
    fetch() {
      this.api.setParams(this.form);
      this.api.fetch();
    },
  },
};
</script>

<style scoped>
.login-title {
  background-image: linear-gradient(
    to right,
    rgba(6, 48, 88),
    rgba(240, 81, 35)
  );
}
.login-container {
  background-color: #fafafb;
  min-height: 100vh; /* Optional: Set minimum height to cover the entire viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    #d4d4d4,
    #e7e7e7,
    #f1f1f1,
    #daf0ff
  );
}

.loginBox {
  max-width: 475px;
  margin: 0 auto;
}
</style>
